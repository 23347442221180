<template>
  <div class="manage-rewards" :class="{skeleton: !state.loaded}">
    <table class="table table-hover" v-if="state.stats.length">
      <thead class="thead-light">
      <tr>
        <th>
          <span>결제 수단</span>
        </th>
        <th>
          <span>후원 금액</span>
        </th>
        <th>
          <span>구성비</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(s, idx) in state.stats" :key="idx">
        <td>
          <span>{{ s.title }}</span>
        </td>
        <td>
          <span>{{ $lib.getNumberFormat(s.amount) }}</span>
        </td>
        <td>
          <span>{{ s.percent }}%</span>
        </td>
      </tr>
      </tbody>
    </table>
    <NoData v-else/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";
import http from "@/scripts/http";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageManagePayMethod";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData},
  mixins: [mixin],
  props: {
    project: Object
  },
  setup() {
    const component = new Component(() => {
      for (let i = 0; i < 4; i += 1) {
        state.stats.push({
          title: "Wait a moment",
          amount: 0
        });
      }
      load();
    });

    const state = reactive({
      loaded: false,
      stats: [],
    });

    const projectSeq = router.app.$route.params.projectSeq;

    const load = () => {
      state.loaded = false;
      http.get(`/api/maker/reward/${projectSeq}/stats/paymethod`).then(({data}) => {
        state.loaded = true;

        const stats = data.body.stats.filter(s => s);
        state.stats = [];

        if (!stats.length) {
          return;
        }

        let totAmount = 0;

        for (let i in stats[0]) {
          totAmount += stats[0][i];
        }

        state.stats.push({
          title: "네이버페이",
          amount: stats[0].naverpay,
          percent: stats[0].naverpay ? (stats[0].naverpay / totAmount * 100).toFixed(2) : 0
        }, {
          title: "카드",
          amount: stats[0].card,
          percent: stats[0].card ? (stats[0].card / totAmount * 100).toFixed(2) : 0
        }, {
          title: "실시간 이체",
          amount: stats[0].realtime,
          percent: stats[0].realtime ? (stats[0].realtime / totAmount * 100).toFixed(2) : 0
        }, {
          title: "가상계좌",
          amount: stats[0].virtual,
          percent: stats[0].virtual ? (stats[0].virtual / totAmount * 100).toFixed(2) : 0
        }, {
          title: "기타",
          amount: stats[0].etc,
          percent: stats[0].etc ? (stats[0].etc / totAmount * 100).toFixed(2) : 0
        });
      });
    };

    return {component, state, load};
  }
});
</script>

<style lang="scss" scoped>
.manage-rewards {
  &.skeleton {
    table {
      th, td {
        span {
          @include skeleton;
        }
      }
    }
  }
}
</style>